import { render, staticRenderFns } from "./SearchFormLanding.vue?vue&type=template&id=c5b06186&scoped=true"
import script from "./SearchFormLanding.vue?vue&type=script&lang=ts"
export * from "./SearchFormLanding.vue?vue&type=script&lang=ts"
import style0 from "./SearchFormLanding.vue?vue&type=style&index=0&id=c5b06186&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5b06186",
  null
  
)

export default component.exports