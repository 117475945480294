
import SearchFormAbstract from '@pp-frontend/shared-packages/classes/search-form/SearchFormAbstract'
import DocCtc from '@pp-frontend/shared-packages/classes/doc-value/DocCtc'
import { FieldsId } from '@pp-frontend/shared-packages/types/enum/FieldsId'
import { TheMask } from 'vue-the-mask'
import { Component, Prop, Ref } from 'nuxt-property-decorator'
import LicencePlateInput from '../LicencePlateInput.vue'
import InfoBtn from './InfoBtn.vue'
import EmailForm from './EmailForm.vue'
import { ErrorValidation } from '~/types/ErrorValidation'
import SearchFormOfertaText from '~/components/search-form/SearchFormOfertaText.vue'
@Component({
  components: {
    SearchFormOfertaText,
    TheMask,
    LicencePlateInput,
    InfoBtn,
    EmailForm
  }
})
export default class extends SearchFormAbstract {
  @Prop({ default: true }) showLicencePlate!: boolean
  @Prop({ default: false }) isGrzAvailable: boolean
  @Ref() ctcField!: HTMLInputElement
  @Prop() dataErrorFormValidations!: ErrorValidation
  formData = new DocCtc()
  fieldId = FieldsId.CTC
  isClientErrorValid: string = ''
  isFormSubmitted = false
  maskTokens = {
    '#': { pattern: /\d/ },
    F: {
      pattern: /[0-9a-zA-Zа-яА-я]/,
      transform: (v: string) => v.toLocaleUpperCase()
    }
  }

  onInputLicence (e: any) {
    (this.$v.formData.additional_fields as any).licence_plate.$reset()
    if (e.length === 9) {
      this.$nextTick(() => (this.ctcField as any).$el.focus())
    }
  }

  isEmailVisible () {
    if (this.formData.document_value.length >= 10 && !this.$store.getters['user/getUser'].email?.length && !this.isFormSubmitted) {
      return true
    }
    return false
  }

  afterSubmitBehavior (): Promise<any> {
    this.$nuxt.$eventBus.$emit('form-with-email')
    setTimeout(() => {
      this.isFormSubmitted = true
    }, 1500)
    return this.$router.push({
      path: '/search/' + this.getType(),
      // @ts-ignore
      query: {
        document_type: this.formData.docType(),
        lead_source_id: window.$nuxt.$route.query.lead_source_id,
        ...this.formData
      }
    })
  }

  errorClientValid () {
    this.isClientErrorValid = ''
    this.$v.$touch()
    if (!this.dataErrorFormValidations.isError) {
      // @ts-ignore
      if (this.$v.formData.additional_fields.$error) {
        this.isClientErrorValid = ' гос. номеров: <span class="error-msg__item">A 000 AA 000</span> <span class="error-msg__item">A 000 AA 00</span>'
        // @ts-ignore
      } else if (this.$v.formData.document_value.$error) {
        this.isClientErrorValid = ' свидетельства о регистрации ТС: <span class="error-msg__item">00 АА 000000</span> <span class="error-msg__item">00 00 000000</span>'
      }
    }
  }

  getType (): string {
    return 'gibdd'
  }
}
